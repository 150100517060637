export default {
  methods: {
    async dismiss (key, metadata = {}) {
      if (this.isDismissed(key)) {
        return
      }

      this.$auth.setUser({
        ...this.$auth.user?.user,
        dismissed_keys: [...this.getDismissedKeys(), key]
      })
      await this.$api.dismissals.store({ key, metadata })
    },
    getDismissedKeys () {
      return this.$auth.user?.user?.dismissed_keys || []
    },
    isDismissed (key) {
      return this.getDismissedKeys().includes(key)
    }
  }
}
