export default $axios => ({
  index () {
    return $axios.get('me')
  },

  update (data) {
    return $axios.put('workspace', data)
  },

  updateUser (data) {
    return $axios.put('auth/user', data)
  },

  logoutUser () {
    return $axios.post('auth/logout')
  }
})
