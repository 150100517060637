
import CogwheelMenu from '@/components/next/layout/cogwheel/CogwheelMenu'
import NavigationTopItems from '@/components/next/layout/navigation/NavigationTopItems'
import NavigationBottomItems from '@/components/next/layout/navigation/NavigationBottomItems'
import LegalLinks from '@/components/next/layout/legal/LegalLinks'
import FlashSaleSidebarBanner from '@/components/next/layout/flashsale/FlashSaleSidebarBanner.vue'
import NavigationCertificatesStandaloneTopItems
  from '@/components/next/layout/navigation/NavigationCertificateStandaloneTopItems.vue'
import WorkspaceSwitcher from '@/components/next/layout/workspace_switcher/WorkspaceSwitcher.vue'
import NavigationBranding from '@/components/next/layout/navigation/NavigationBranding.vue'

export default {
  name: 'LayoutSidebar',
  components: {
    NavigationBranding,
    WorkspaceSwitcher,
    NavigationCertificatesStandaloneTopItems,
    CogwheelMenu,
    NavigationTopItems,
    NavigationBottomItems,
    LegalLinks,
    FlashSaleSidebarBanner
  },

  data () {
    return {
      workspace: null
    }
  }
}
