import common from '@/strings/common'

import CfAlertModal from '@/components/next/ui/modal/CfAlertModal.vue'
import CfConfirmationModal
  from '@/components/next/ui/modal/CfConfirmationModal.vue'
import OfferModal
  from '@/components/next/workspace/billing/offers/OfferModal.vue'

const expectedApiRevision = 7
const revisionHeader = 'x-campflow-api-revision'

export default ({ app, $auth, $axios, $modal, $access }, inject) => {
  function chooseSubscription (featureUnavailable = false) {
    $modal(OfferModal, {
      featureUnavailable
    }, 'error:subscription')
  }

  function showNetworkError () {
    $modal(CfConfirmationModal, {
      heading: common.network_error.title,
      explanation: common.network_error.explanation,
      action: common.network_error.reload_now,
      icon: 'wifi-exclamation',
      look: 'light'
    }, 'error:network').then((shouldReload) => {
      if (shouldReload) {
        window.location.reload()
      }
    })
  }

  function showGenericError () {
    $modal(CfAlertModal, {
      heading: common.generic_error.title,
      explanation: common.generic_error.explanation,
      look: 'light'
    }, 'error:generic')
  }

  function showUnauthorizedError () {
    $modal(CfAlertModal, {
      heading: common.permission_error.title,
      explanation: common.permission_error.explanation,
      look: 'light',
      icon: 'lock'
    }, 'error:permission')
  }

  function showNotFoundError () {
    $modal(CfAlertModal, {
      heading: common.not_found_error.title,
      explanation: common.not_found_error.explanation,
      close: common.not_found_error.go_home,
      look: 'light',
      icon: 'circle-dashed'
    }, 'error:not_found').then(() => {
      $auth.fetchUser()
      app.router.push('/')
    })
  }

  function showApiRevisionError () {
    $modal(CfConfirmationModal, {
      heading: common.api_revision_error.title,
      explanation: common.api_revision_error.explanation,
      explanation2: common.api_revision_error.explanation2,
      action: common.api_revision_error.reload_now,
      cancel: common.api_revision_error.ignore,
      icon: 'sync',
      look: 'primary'
    }, 'error:api_revision').then((shouldReload) => {
      if (shouldReload) {
        window.location.reload()
      }
    })
  }

  inject('errors', {
    isValidationError (err) {
      return err.response?.status === 422
    },

    getValidationErrors (err) {
      return Object.keys(err?.response?.data?.errors || {})
    },

    handle (err) {
      this.handles(err, true)
    },

    handles (err, rethrow = true) {
      switch (err?.response?.status) {
        case 401:
          window.location.replace(process.env.AUTH_BASE_URL + '/login?redirectUrl=' + encodeURIComponent(window.location.href))
          return true
        case 402:
          chooseSubscription(true)
          return true
        case 403:
          showUnauthorizedError()
          return true
        case 404:
          showNotFoundError()
          return true
        case 503:
          window.location.replace(process.env.AUTH_BASE_URL + '/maintenance')
          return true
        case undefined:
          if (err.message === 'Network Error') {
            showNetworkError()
            return true
          }
      }

      showGenericError()
      if (rethrow) {
        throw err
      }
      return false
    },

    chooseSubscription
  })

  // Tell the server the expected API revision
  $axios.interceptors.request.use((config) => {
    config.headers[revisionHeader] = expectedApiRevision
    return config
  })

  // Check that the X-Campflow-Api-Revision header is set to the correct number
  $axios.onResponse((response) => {
    if (!response.headers[revisionHeader]) {
      // eslint-disable-next-line no-console
      console.warn(revisionHeader + ' header not found')
      return
    }

    const actualApiRevision = parseInt(
      response.headers[revisionHeader]
    )

    if (response.headers['content-type'] !== 'application/json') {
      // Non-JSON responses such as images might be cached and still have
      // the old revision number. We don't want to show an error in that case.
      return
    }

    if (actualApiRevision !== expectedApiRevision) {
      showApiRevisionError()
    }
  })
}
